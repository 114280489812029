import { LiteratureIllustrationContainer } from "../styles/literature";

import {
  RecentButtonsContainer,
  RecentCardBody,
  RecentCardContainer,
  RecentCardTitle,
  RecentCarouselContainer,
  RecentContainer,
  RecentSlide,
  RecentSlide1,
  RecentSlide2,
  RecentSlide3,
  RecentTitleContainer,
} from "../styles/recent";
import { RedTitle } from "../styles/styles";

import Asset_1 from "../assets/images/Asset 1.svg";

import LuisitoC from "../assets/images/LuisitoC.jpg";
import CocaCola from "../assets/images/CocaCola.jpg";
import Sainz from "../assets/images/Sainz.jpg";
import Yoplait_Completa from "../assets/images/Yoplait_Completa.jpg";
import Bimbo_Completa from "../assets/images/Bimbo_Completa.jpg";
import Chokokrispis from "../assets/images/Chokokrispis.jpg";

import granmalo_luisito from "../assets/video/granmalo_luisito.mp4";
import fasfu_luisito from "../assets/video/fasfu_luisito.mp4";
import cocacola_andrea_bentley from "../assets/video/cocacola_andrea_bentley.mp4";
import cocacola_ama_blitz from "../assets/video/cocacola_ama_blitz.mp4";
import cocacola_cherry_dii from "../assets/video/cocacola_cherry_dii.mp4";
import ines_saenz from "../assets/video/ines_saenz.mp4";

import littlebaby_lia from "../assets/video/littlebaby_lia.mp4";
import sharon_pop_oficial from "../assets/video/sharon_pop_oficial.mp4";
import jorgelozanoh from "../assets/images/20230519_jorgelozanoh.jpg";
import rominaycamila from "../assets/images/20230519_rominaycamila.jpg";
import angelatasyblog_empanizado from "../assets/video/20230504_angelatasyblog_empanizado.mp4";
import manuelaguiarpeon_empanizado from "../assets/video/20230509_manuelaguiarpeon_empanizado.mp4";
import marchenacindy_empanizado from "../assets/video/20230517_marchenacindy_empanizado.mp4";
import danielazzuara_empanizado from "../assets/video/20230518_danielazzuara_empanizado.mp4";

import chokokrispis_marj from "../assets/video/20240603_marjorielm123_Kellogs.mp4";
import chokokrispis_marj_2 from "../assets/video/20240603_marjorlielm123_Kellogs_2.mp4";
import chokokrispis_habanazoe from "../assets/video/20240608_habanazoe_Kellogs.mp4";
import chokokrispis_habanazoe_2 from "../assets/video/20240606_habanazoe_Kellogs_2.mp4";
import chokokrispis_janifukui from "../assets/video/20240609_janifukui_Kellogs.mp4";
import chokokrispis_pakocedillo from "../assets/video/20240609_pakocedillo_Kellogs.mp4";

import fernada_samano_empanizado_img from "../assets/images/20230505_fernada_samano_empanizado.jpg";
import manuelaguiarpeon_empanizado_img from "../assets/images/20230505_manuelaguiarpeon_empanizado.png";
import danielazzuara_empanizado_img from "../assets/images/20230511_danielazzuara_empanizado.png";

import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import { selectedCampaignAtom } from "../store/store";

import "animate.css";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const campaigns = [
  {
    id: 1,
    prevId: 5,
    nextId: 2,
    image: Yoplait_Completa,
    name: "Yoplait",
    slug: "yoplait",
    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/yoplait1.mp4",
        name: "Yoplait",
        video: sharon_pop_oficial,
        date: "19.05.2023",
        text: "La multinacional Yoplait lanzó una campaña para su nueva línea de yogurt griego bebible. En dicha campaña aparentemente utiliza influencers infantiles. Las y los niños quienes aparecen rodeados del kit de productos de Yoplait, presumiendo que se trata de un yogurt reducido en grasa, sin azúcares añadidos, rico y con aporte de proteína. Uno de los videos que compartimos en el observatorio es de la cuenta @liafranco_oficial (314 seguidores en Instagram). Esta influencer infantil se describe como “Artista, Modelo y Actriz” y menciona que la cuenta es manejada por su mami. Además de promocionar Yoplait, esta influencer infantil hace publicidad constante para juguetes y ropa. En nuestro informe de #ChatarraInfluencer (link) documentamos más casos de influencers infantiles. No solamente este tipo de publicidad usando niñas y niños es ilegal, también es una vergüenza ver cómo sus papás aprovechan a sus hijos e hijas para fines económicos.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de sharon_pop_oficial tomada el 19.05.2023",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/yoplait2.mp4",
        name: "Yoplait",
        video: littlebaby_lia,
        date: "19.05.2023",
        text: "La multinacional Yoplait lanzó una campaña para su nueva línea de yogurt griego bebible. En dicha campaña aparentemente utiliza influencers infantiles. Las y los niños quienes aparecen rodeados del kit de productos de Yoplait, presumiendo que se trata de un yogurt reducido en grasa, sin azúcares añadidos, rico y con aporte de proteína. Uno de los videos que compartimos en el observatorio es de la cuenta @liafranco_oficial (314 seguidores en Instagram). Esta influencer infantil se describe como “Artista, Modelo y Actriz” y menciona que la cuenta es manejada por su mami. Además de promocionar Yoplait, esta influencer infantil hace publicidad constante para juguetes y ropa. En nuestro informe de #ChatarraInfluencer (link) documentamos más casos de influencers infantiles. No solamente este tipo de publicidad usando niñas y niños es ilegal, también es una vergüenza ver cómo sus papás aprovechan a sus hijos e hijas para fines económicos.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de littlebaby_lia y liafranco_oficial tomada el 19.05.2023",
      },
      {
        type: "image",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20230519_jorgelozanoh.jpg",
        name: "Yoplait",
        video: jorgelozanoh,
        date: "19.05.2023",
        text: "La multinacional Yoplait lanzó una campaña para su nueva línea de yogurt griego bebible. En dicha campaña aparentemente utiliza influencers infantiles. Las y los niños quienes aparecen rodeados del kit de productos de Yoplait, presumiendo que se trata de un yogurt reducido en grasa, sin azúcares añadidos, rico y con aporte de proteína. Uno de los videos que compartimos en el observatorio es de la cuenta @liafranco_oficial (314 seguidores en Instagram). Esta influencer infantil se describe como “Artista, Modelo y Actriz” y menciona que la cuenta es manejada por su mami. Además de promocionar Yoplait, esta influencer infantil hace publicidad constante para juguetes y ropa. En nuestro informe de #ChatarraInfluencer (link) documentamos más casos de influencers infantiles. No solamente este tipo de publicidad usando niñas y niños es ilegal, también es una vergüenza ver cómo sus papás aprovechan a sus hijos e hijas para fines económicos.",
        footnote:
          "Captura de pantalla de la cuenta de Instagram de jorgelozanoh tomada el 19.05.2023",
      },
      {
        type: "image",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20230519_rominaycamila.jpg",
        name: "Yoplait",
        video: rominaycamila,
        date: "19.05.2023",
        text: "La multinacional Yoplait lanzó una campaña para su nueva línea de yogurt griego bebible. En dicha campaña aparentemente utiliza influencers infantiles. Las y los niños quienes aparecen rodeados del kit de productos de Yoplait, presumiendo que se trata de un yogurt reducido en grasa, sin azúcares añadidos, rico y con aporte de proteína. Uno de los videos que compartimos en el observatorio es de la cuenta @liafranco_oficial (314 seguidores en Instagram). Esta influencer infantil se describe como “Artista, Modelo y Actriz” y menciona que la cuenta es manejada por su mami. Además de promocionar Yoplait, esta influencer infantil hace publicidad constante para juguetes y ropa. En nuestro informe de #ChatarraInfluencer (link) documentamos más casos de influencers infantiles. No solamente este tipo de publicidad usando niñas y niños es ilegal, también es una vergüenza ver cómo sus papás aprovechan a sus hijos e hijas para fines económicos.",
        footnote:
          "Captura de pantalla de la cuenta de Instagram de rominaycamila tomada el 19.05.2023",
      },
    ],
  },
  {
    id: 2,
    prevId: 1,
    nextId: 3,
    image: Bimbo_Completa,
    name: "Bimbo",
    slug: "bimbo",
    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/bimbo1.mp4",
        name: "Bimbo",
        video: angelatasyblog_empanizado,
        date: "04.05.2023",
        text: "La línea de empanizadores “Cero Cero”, “Crujiente” y “Clásico” de Bimbo, promocionada engañosamente por influencers “foodies” y “fitness”, no sólo aporta un exceso de calorías NO indispensables (como sus sellos lo indican) sino también contiene aditivos tóxicos. Esto último ha sido documentado por El Poder del Consumidor en sus radiografías. Las y los influencers que participan en esta campaña incitan a sus seguidores a preparar la misma receta “saludable”, pues utilizan verduras como base para el empanizado y acompañan con ensalada. No es la primera campaña engañosa de influencers que Bimbo pone en marcha. En el informe #ChatarraInfluencer reportamos la campaña #RutaDelaHamburguesa sobre su pan para hacer este tipo de platillos (link).",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de angelatasyblog tomada el 04.05.2023",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/bimbo2.mp4",
        name: "Bimbo",
        video: manuelaguiarpeon_empanizado,
        date: "09.05.2023",
        text: "La línea de empanizadores “Cero Cero”, “Crujiente” y “Clásico” de Bimbo, promocionada engañosamente por influencers “foodies” y “fitness”, no sólo aporta un exceso de calorías NO indispensables (como sus sellos lo indican) sino también contiene aditivos tóxicos. Esto último ha sido documentado por El Poder del Consumidor en sus radiografías. Las y los influencers que participan en esta campaña incitan a sus seguidores a preparar la misma receta “saludable”, pues utilizan verduras como base para el empanizado y acompañan con ensalada. No es la primera campaña engañosa de influencers que Bimbo pone en marcha. En el informe #ChatarraInfluencer reportamos la campaña #RutaDelaHamburguesa sobre su pan para hacer este tipo de platillos (link).",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de manuelaguiarpeon tomada el 09.05.2023",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/bimbo3.mp4",
        name: "Bimbo",
        video: marchenacindy_empanizado,
        date: "17.05.2023",
        text: "La línea de empanizadores “Cero Cero”, “Crujiente” y “Clásico” de Bimbo, promocionada engañosamente por influencers “foodies” y “fitness”, no sólo aporta un exceso de calorías NO indispensables (como sus sellos lo indican) sino también contiene aditivos tóxicos. Esto último ha sido documentado por El Poder del Consumidor en sus radiografías. Las y los influencers que participan en esta campaña incitan a sus seguidores a preparar la misma receta “saludable”, pues utilizan verduras como base para el empanizado y acompañan con ensalada. No es la primera campaña engañosa de influencers que Bimbo pone en marcha. En el informe #ChatarraInfluencer reportamos la campaña #RutaDelaHamburguesa sobre su pan para hacer este tipo de platillos (link).",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de marchenacindy tomada el 17.05.2023",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/bimbo4.mp4",
        name: "Bimbo",
        video: danielazzuara_empanizado,
        date: "18.05.2023",
        text: "La línea de empanizadores “Cero Cero”, “Crujiente” y “Clásico” de Bimbo, promocionada engañosamente por influencers “foodies” y “fitness”, no sólo aporta un exceso de calorías NO indispensables (como sus sellos lo indican) sino también contiene aditivos tóxicos. Esto último ha sido documentado por El Poder del Consumidor en sus radiografías. Las y los influencers que participan en esta campaña incitan a sus seguidores a preparar la misma receta “saludable”, pues utilizan verduras como base para el empanizado y acompañan con ensalada. No es la primera campaña engañosa de influencers que Bimbo pone en marcha. En el informe #ChatarraInfluencer reportamos la campaña #RutaDelaHamburguesa sobre su pan para hacer este tipo de platillos (link).",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de danielazzuara tomada el 18.05.2023",
      },
      {
        type: "image",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20230505_manuelaguiarpeon_empanizado.png",
        name: "Bimbo",
        video: manuelaguiarpeon_empanizado_img,
        date: "09.05.2023",
        text: "La línea de empanizadores “Cero Cero”, “Crujiente” y “Clásico” de Bimbo, promocionada engañosamente por influencers “foodies” y “fitness”, no sólo aporta un exceso de calorías NO indispensables (como sus sellos lo indican) sino también contiene aditivos tóxicos. Esto último ha sido documentado por El Poder del Consumidor en sus radiografías. Las y los influencers que participan en esta campaña incitan a sus seguidores a preparar la misma receta “saludable”, pues utilizan verduras como base para el empanizado y acompañan con ensalada. No es la primera campaña engañosa de influencers que Bimbo pone en marcha. En el informe #ChatarraInfluencer reportamos la campaña #RutaDelaHamburguesa sobre su pan para hacer este tipo de platillos (link).",
        footnote:
          "Campura de pantalla de la cuenta de Instagram de manuelaguiarpeon tomada el 09.05.2023",
      },
      {
        type: "image",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20230511_danielazzuara_empanizado.png",
        name: "Bimbo",
        video: danielazzuara_empanizado_img,
        date: "11.05.2023",
        text: "La línea de empanizadores “Cero Cero”, “Crujiente” y “Clásico” de Bimbo, promocionada engañosamente por influencers “foodies” y “fitness”, no sólo aporta un exceso de calorías NO indispensables (como sus sellos lo indican) sino también contiene aditivos tóxicos. Esto último ha sido documentado por El Poder del Consumidor en sus radiografías. Las y los influencers que participan en esta campaña incitan a sus seguidores a preparar la misma receta “saludable”, pues utilizan verduras como base para el empanizado y acompañan con ensalada. No es la primera campaña engañosa de influencers que Bimbo pone en marcha. En el informe #ChatarraInfluencer reportamos la campaña #RutaDelaHamburguesa sobre su pan para hacer este tipo de platillos (link).",
        footnote:
          "Captura de pantalla de la cuenta de Instagram de danielazzuara tomada el 11.05.2023",
      },
      {
        type: "image",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20230505_fernada_samano_empanizado.jpg",
        name: "Bimbo",
        video: fernada_samano_empanizado_img,
        date: "05.05.2023",
        text: "La línea de empanizadores “Cero Cero”, “Crujiente” y “Clásico” de Bimbo, promocionada engañosamente por influencers “foodies” y “fitness”, no sólo aporta un exceso de calorías NO indispensables (como sus sellos lo indican) sino también contiene aditivos tóxicos. Esto último ha sido documentado por El Poder del Consumidor en sus radiografías. Las y los influencers que participan en esta campaña incitan a sus seguidores a preparar la misma receta “saludable”, pues utilizan verduras como base para el empanizado y acompañan con ensalada. No es la primera campaña engañosa de influencers que Bimbo pone en marcha. En el informe #ChatarraInfluencer reportamos la campaña #RutaDelaHamburguesa sobre su pan para hacer este tipo de platillos (link).",
        footnote:
          "Captura de pantalla de la cuenta de Instagram de fernanda_samano tomada el 05.05.2023",
      },
    ],
  },
  {
    id: 3,
    prevId: 2,
    nextId: 4,
    image: LuisitoC,
    name: "Luisito Comunica",
    slug: "luisito",
    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/granmalo_luisito.mp4",
        name: "Luisito Comunica",
        video: granmalo_luisito,
        date: "05/01/2022",
        text: "Luisito Comunica, con 33.4 millones de seguidores en Instagram y 40 millones de suscriptores en YouTube, promociona a menores de manera engañosa su propia bebida alcohólica violando el Reglamento de la Ley General de Salud en Materia de Publicidad. Este influencer es una máquina de publicidad engañosa.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Luisito Comunica tomada el 05.01.2022",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/fasfu_luisito.mp4",
        name: "Luisito Comunica",
        video: granmalo_luisito,
        date: "07/04/2023",
        text: "Luisito Comunica, con 33.4 millones de seguidores en Instagram y 40 millones de suscriptores en YouTube, promociona a menores de manera engañosa su propia bebida alcohólica violando el Reglamento de la Ley General de Salud en Materia de Publicidad. Este influencer es una máquina de publicidad engañosa.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Luisito Comunica tomada el 07.04.2023",
      },
    ],
  },
  {
    id: 4,
    prevId: 3,
    nextId: 5,
    image: CocaCola,
    name: "Coca Cola",
    slug: "coca_cola",
    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/cocacola_ama_blitz.mp4",
        name: "Coca Cola",
        video: cocacola_ama_blitz,
        date: "12.02.2023",
        text: "La refresquera multi-nacional hace una colaboración con la cantante habla-hispana del momento “Rosalía”. Para promocionar los refrescos de esta colaboración, Coca-Cola utiliza a decenas de influencers. La dinámica es la misma, las y los influencers reciben “un regalito” por parte que Coca-Cola que incluye los refrescos edición limitada en una caja, lentes y audífonos. Las y los influencers etiquetan la cuenta de Coca Cola México en Instagram y utilizan etiquetas insignia.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Amablitz tomada el 12.02.2023",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/cocacola_andrea_bentley.mp4",
        name: "Coca Cola",
        video: cocacola_andrea_bentley,
        date: "28.02.2023",
        text: "La refresquera multi-nacional hace una colaboración con la cantante habla-hispana del momento “Rosalía”. Para promocionar los refrescos de esta colaboración, Coca-Cola utiliza a decenas de influencers. La dinámica es la misma, las y los influencers reciben “un regalito” por parte que Coca-Cola que incluye los refrescos edición limitada en una caja, lentes y audífonos. Las y los influencers etiquetan la cuenta de Coca Cola México en Instagram y utilizan etiquetas insignia.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de CherryDii tomada el 28.02.2023",
      },
    ],
  },
  {
    id: 5,
    prevId: 4,
    nextId: 1,
    image: Chokokrispis,
    name: "Choko Krispis",
    slug: "choko_krispis",
    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20240603_marjorielm123_Kellogs.mp4",
        name: "Choko Krispis",
        video: chokokrispis_marj,
        date: "03.06.2024",
        text: "En su afán de hacer adictos al azúcar a las próximas generaciones, ChocoKrispis de Kellogg's lanzó en junio 2024 su nuevo producto ultra procesado denominado “ChocoKrispis Boom” con una campaña publicitaria dirigida descaradamente dirigida a niños, niñas y adolescentes. ¿Su estrategia? Una invasión de influencers infantiles en Kidzania, un centro de entretenimiento y 'educación' infantil. Allí, figuras como Ana Paula Serrado (@el_mundo_con_pau), Anabel García (@regislandmx), Habana Zoé (@habana_zoe) y las hermanas Paulina y Ariadna (@ariadnepaulinaoficial) se aliaron económicamente con Kellogg's para promocionar engañosamente el nuevo cereal. Las y los influencers que asistieron al lanzamiento compartieron en historias y publicaciones de Instagram cómo comían este cereal, preparaban su propia barra de cereal con dulces y otros productos chatarra, jugaban en la estación de fábrica de cereal que Kidzania e interactuaban con imágenes del nuevo cereal hechas con inteligencia artificial. Como parte de la campaña de lanzamiento, Kellogs también envió “unboxings”. ¿El resultado? Niños bombardeados con publicidad engañosa que presenta a los Choco Krispis Boom como algo 'delicioso' y 'crunchy', cuando en realidad es una bomba de azúcar que equivale a casi 4 cucharadas de azúcar por plato, según la radiografía de El Poder del Consumidor. Las niñas y los niños son especialmente susceptibles a la influencia de roles que siguen (en este caso sus influencers), es decir son un blanco fácil para la mercadotécnica persuasiva. Con esta campaña, Kellogs y ChocoKrispis nos muestran que sus lineamientos publicitarios están enfocados en manipular a la población infantil y vulnerar su salud.",
        footnote:
          "Grabación de la cuenta de Instagram @marj.orielm123_, tomada el 03.06.2024",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20240603_marjorlielm123_Kellogs_2.mp4",
        name: "Choko Krispis",
        video: chokokrispis_marj_2,
        date: "03.06.2024",
        text: "En su afán de hacer adictos al azúcar a las próximas generaciones, ChocoKrispis de Kellogg's lanzó en junio 2024 su nuevo producto ultra procesado denominado “ChocoKrispis Boom” con una campaña publicitaria dirigida descaradamente dirigida a niños, niñas y adolescentes. ¿Su estrategia? Una invasión de influencers infantiles en Kidzania, un centro de entretenimiento y 'educación' infantil. Allí, figuras como Ana Paula Serrado (@el_mundo_con_pau), Anabel García (@regislandmx), Habana Zoé (@habana_zoe) y las hermanas Paulina y Ariadna (@ariadnepaulinaoficial) se aliaron económicamente con Kellogg's para promocionar engañosamente el nuevo cereal. Las y los influencers que asistieron al lanzamiento compartieron en historias y publicaciones de Instagram cómo comían este cereal, preparaban su propia barra de cereal con dulces y otros productos chatarra, jugaban en la estación de fábrica de cereal que Kidzania e interactuaban con imágenes del nuevo cereal hechas con inteligencia artificial. Como parte de la campaña de lanzamiento, Kellogs también envió “unboxings”. ¿El resultado? Niños bombardeados con publicidad engañosa que presenta a los Choco Krispis Boom como algo 'delicioso' y 'crunchy', cuando en realidad es una bomba de azúcar que equivale a casi 4 cucharadas de azúcar por plato, según la radiografía de El Poder del Consumidor. Las niñas y los niños son especialmente susceptibles a la influencia de roles que siguen (en este caso sus influencers), es decir son un blanco fácil para la mercadotécnica persuasiva. Con esta campaña, Kellogs y ChocoKrispis nos muestran que sus lineamientos publicitarios están enfocados en manipular a la población infantil y vulnerar su salud.",
        footnote:
          "Grabación de la cuenta de Instagram @marj.orielm123_, tomada el 03.06.2024",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20240608_habanazoe_Kellogs.mp4",
        name: "Choko Krispis",
        video: chokokrispis_habanazoe,
        date: "06.06.2024",
        text: "En su afán de hacer adictos al azúcar a las próximas generaciones, ChocoKrispis de Kellogg's lanzó en junio 2024 su nuevo producto ultra procesado denominado “ChocoKrispis Boom” con una campaña publicitaria dirigida descaradamente dirigida a niños, niñas y adolescentes. ¿Su estrategia? Una invasión de influencers infantiles en Kidzania, un centro de entretenimiento y 'educación' infantil. Allí, figuras como Ana Paula Serrado (@el_mundo_con_pau), Anabel García (@regislandmx), Habana Zoé (@habana_zoe) y las hermanas Paulina y Ariadna (@ariadnepaulinaoficial) se aliaron económicamente con Kellogg's para promocionar engañosamente el nuevo cereal. Las y los influencers que asistieron al lanzamiento compartieron en historias y publicaciones de Instagram cómo comían este cereal, preparaban su propia barra de cereal con dulces y otros productos chatarra, jugaban en la estación de fábrica de cereal que Kidzania e interactuaban con imágenes del nuevo cereal hechas con inteligencia artificial. Como parte de la campaña de lanzamiento, Kellogs también envió “unboxings”. ¿El resultado? Niños bombardeados con publicidad engañosa que presenta a los Choco Krispis Boom como algo 'delicioso' y 'crunchy', cuando en realidad es una bomba de azúcar que equivale a casi 4 cucharadas de azúcar por plato, según la radiografía de El Poder del Consumidor. Las niñas y los niños son especialmente susceptibles a la influencia de roles que siguen (en este caso sus influencers), es decir son un blanco fácil para la mercadotécnica persuasiva. Con esta campaña, Kellogs y ChocoKrispis nos muestran que sus lineamientos publicitarios están enfocados en manipular a la población infantil y vulnerar su salud.",
        footnote:
          "Grabación de la cuenta de Instagram @habana_zoe_, tomada el 06.06.2024",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20240606_habanazoe_Kellogs_2.mp4",
        name: "Choko Krispis",
        video: chokokrispis_habanazoe_2,
        date: "08.06.2024",
        text: "En su afán de hacer adictos al azúcar a las próximas generaciones, ChocoKrispis de Kellogg's lanzó en junio 2024 su nuevo producto ultra procesado denominado “ChocoKrispis Boom” con una campaña publicitaria dirigida descaradamente dirigida a niños, niñas y adolescentes. ¿Su estrategia? Una invasión de influencers infantiles en Kidzania, un centro de entretenimiento y 'educación' infantil. Allí, figuras como Ana Paula Serrado (@el_mundo_con_pau), Anabel García (@regislandmx), Habana Zoé (@habana_zoe) y las hermanas Paulina y Ariadna (@ariadnepaulinaoficial) se aliaron económicamente con Kellogg's para promocionar engañosamente el nuevo cereal. Las y los influencers que asistieron al lanzamiento compartieron en historias y publicaciones de Instagram cómo comían este cereal, preparaban su propia barra de cereal con dulces y otros productos chatarra, jugaban en la estación de fábrica de cereal que Kidzania e interactuaban con imágenes del nuevo cereal hechas con inteligencia artificial. Como parte de la campaña de lanzamiento, Kellogs también envió “unboxings”. ¿El resultado? Niños bombardeados con publicidad engañosa que presenta a los Choco Krispis Boom como algo 'delicioso' y 'crunchy', cuando en realidad es una bomba de azúcar que equivale a casi 4 cucharadas de azúcar por plato, según la radiografía de El Poder del Consumidor. Las niñas y los niños son especialmente susceptibles a la influencia de roles que siguen (en este caso sus influencers), es decir son un blanco fácil para la mercadotécnica persuasiva. Con esta campaña, Kellogs y ChocoKrispis nos muestran que sus lineamientos publicitarios están enfocados en manipular a la población infantil y vulnerar su salud.",
        footnote:
          "Grabación de la cuenta de Instagram @habana_zoe_, tomada el 08.06.2024",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20240609_janifukui_Kellogs.mp4",
        name: "Choko Krispis",
        video: chokokrispis_janifukui,
        date: "09.06.2024",
        text: "En su afán de hacer adictos al azúcar a las próximas generaciones, ChocoKrispis de Kellogg's lanzó en junio 2024 su nuevo producto ultra procesado denominado “ChocoKrispis Boom” con una campaña publicitaria dirigida descaradamente dirigida a niños, niñas y adolescentes. ¿Su estrategia? Una invasión de influencers infantiles en Kidzania, un centro de entretenimiento y 'educación' infantil. Allí, figuras como Ana Paula Serrado (@el_mundo_con_pau), Anabel García (@regislandmx), Habana Zoé (@habana_zoe) y las hermanas Paulina y Ariadna (@ariadnepaulinaoficial) se aliaron económicamente con Kellogg's para promocionar engañosamente el nuevo cereal. Las y los influencers que asistieron al lanzamiento compartieron en historias y publicaciones de Instagram cómo comían este cereal, preparaban su propia barra de cereal con dulces y otros productos chatarra, jugaban en la estación de fábrica de cereal que Kidzania e interactuaban con imágenes del nuevo cereal hechas con inteligencia artificial. Como parte de la campaña de lanzamiento, Kellogs también envió “unboxings”. ¿El resultado? Niños bombardeados con publicidad engañosa que presenta a los Choco Krispis Boom como algo 'delicioso' y 'crunchy', cuando en realidad es una bomba de azúcar que equivale a casi 4 cucharadas de azúcar por plato, según la radiografía de El Poder del Consumidor. Las niñas y los niños son especialmente susceptibles a la influencia de roles que siguen (en este caso sus influencers), es decir son un blanco fácil para la mercadotécnica persuasiva. Con esta campaña, Kellogs y ChocoKrispis nos muestran que sus lineamientos publicitarios están enfocados en manipular a la población infantil y vulnerar su salud.",
        footnote:
          "Grabación de la cuenta de Instagram @janifukui, tomada el 09.06.2024",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20240609_pakocedillo_Kellogs.mp4",
        name: "Choko Krispis",
        video: chokokrispis_pakocedillo,
        date: "09.06.2024",
        text: "En su afán de hacer adictos al azúcar a las próximas generaciones, ChocoKrispis de Kellogg's lanzó en junio 2024 su nuevo producto ultra procesado denominado “ChocoKrispis Boom” con una campaña publicitaria dirigida descaradamente dirigida a niños, niñas y adolescentes. ¿Su estrategia? Una invasión de influencers infantiles en Kidzania, un centro de entretenimiento y 'educación' infantil. Allí, figuras como Ana Paula Serrado (@el_mundo_con_pau), Anabel García (@regislandmx), Habana Zoé (@habana_zoe) y las hermanas Paulina y Ariadna (@ariadnepaulinaoficial) se aliaron económicamente con Kellogg's para promocionar engañosamente el nuevo cereal. Las y los influencers que asistieron al lanzamiento compartieron en historias y publicaciones de Instagram cómo comían este cereal, preparaban su propia barra de cereal con dulces y otros productos chatarra, jugaban en la estación de fábrica de cereal que Kidzania e interactuaban con imágenes del nuevo cereal hechas con inteligencia artificial. Como parte de la campaña de lanzamiento, Kellogs también envió “unboxings”. ¿El resultado? Niños bombardeados con publicidad engañosa que presenta a los Choco Krispis Boom como algo 'delicioso' y 'crunchy', cuando en realidad es una bomba de azúcar que equivale a casi 4 cucharadas de azúcar por plato, según la radiografía de El Poder del Consumidor. Las niñas y los niños son especialmente susceptibles a la influencia de roles que siguen (en este caso sus influencers), es decir son un blanco fácil para la mercadotécnica persuasiva. Con esta campaña, Kellogs y ChocoKrispis nos muestran que sus lineamientos publicitarios están enfocados en manipular a la población infantil y vulnerar su salud.",
        footnote:
          "Grabación de la cuenta de Instagram @pako.cedillo, tomada el 09.06.2024",
      },
    ],
  },
];

function Recent() {
  const [selectedCampaign, setSelectedCampaign] = useAtom(selectedCampaignAtom);

  return (
    <RecentContainer>
      <div
        data-aos="zoom-in-right"
        data-aos-duration="1000"
        className="w-[80px] lg:w-[120px] active:w-[70px] active:lg:w-[110px] invisible md:visible absolute left-[20px] lg:left-[80px] 2xl:left-[120px] z-40"
      >
        <img
          className="animate__pulse animate__infinite animate__animated animate__fast"
          src={Asset_1}
          alt="Chatarra Influencer Illustration"
        />
      </div>

      <RecentTitleContainer>
        <RedTitle>Campañas recientes</RedTitle>
      </RecentTitleContainer>
      <RecentCarouselContainer>
        {campaigns.map(c => (
          <RecentSlide id={`slide${c.id}`}>
            <Link
              className="carousel-item cursor-pointer flex-col items-center relative w-full"
              to={`campana/${c.slug}`}
              onClick={() => setSelectedCampaign(c)}
            >
              <RecentCardContainer>
                <figure className="cursor-pointer bg-zinc-900  object-fit">
                  <img className=" cursor-pointer" src={c.image} alt="" />
                </figure>
                <RecentCardBody>
                  <RecentCardTitle>{c.name}</RecentCardTitle>
                  <p>Click para ver el video</p>
                </RecentCardBody>
              </RecentCardContainer>
            </Link>
            <RecentButtonsContainer>
              <a
                href={`#slide${c.prevId}`}
                className="btn btn-circle "
              >
                ❮
              </a>
              <a
                href={`#slide${c.nextId}`}
                className="btn btn-circle "
              >
                ❯
              </a>
            </RecentButtonsContainer>
          </RecentSlide>
        ))}

        {/* <RecentSlide1>
          <RecentCardContainer>
            <figure className="h-[350px] bg-zinc-900">
              <img src={LuisitoC} alt="" />
            </figure>
            <RecentCardBody>
              <RecentCardTitle>Luisito Comunica</RecentCardTitle>
              <p>Click para ver el video</p>
            </RecentCardBody>
          </RecentCardContainer>
          <RecentButtonsContainer>
            <a
              href="#slide3"
              className="btn btn-circle "
            >
              ❮
            </a>
            <a
              href="#slide2"
              className="btn btn-circle mt-[200px] md:mr-24 2xl:mr-[300px]"
            >
              ❯
            </a>
          </RecentButtonsContainer>
        </RecentSlide1>

        <RecentSlide2>
          <RecentCardContainer>
            <figure className="h-[350px] bg-zinc-900">
              <img src={CocaCola} alt="" />
            </figure>
            <RecentCardBody>
              <RecentCardTitle>Coca Cola</RecentCardTitle>
              <p>Click para ver el video</p>
            </RecentCardBody>
          </RecentCardContainer>
          <RecentButtonsContainer>
            <a
              href="#slide1"
              className="btn btn-circle mt-[200px] md:ml-24 2xl:ml-[300px]"
            >
              ❮
            </a>
            <a
              href="#slide3"
              className="btn btn-circle mt-[200px] md:mr-24 2xl:mr-[300px]"
            >
              ❯
            </a>
          </RecentButtonsContainer>
        </RecentSlide2>

        <RecentSlide3>
          <RecentCardContainer>
            <figure className="h-[350px] bg-zinc-900">
              <img src={Sainz} alt="" />
            </figure>
            <RecentCardBody>
              <RecentCardTitle>Inés Sáenz</RecentCardTitle>
              <p>Click para ver el video</p>
            </RecentCardBody>
          </RecentCardContainer>
          <RecentButtonsContainer>
            <a
              href="#slide2"
              className="btn btn-circle mt-[200px] md:ml-24 2xl:ml-[300px]"
            >
              ❮
            </a>
            <a
              href="#slide1"
              className="btn btn-circle mt-[200px] md:mr-24 2xl:mr-[300px]"
            >
              ❯
            </a>
          </RecentButtonsContainer>
        </RecentSlide3> */}
      </RecentCarouselContainer>
    </RecentContainer>
  );
}

export default Recent;
